<!-- 系统设置-用户管理 -->
<template>
    <div class="content-main">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <div class="filter-wrap">
                    <expand-filter
                        :closeWidth="680"
                        :formData="formData"
                        marginBottom="0px"
                        @clickBtn="clickBtn"
                        @changeBtnFormType="changeBtnFormType"
                    ></expand-filter>
                    <div class="button-line" v-hasPermi="['user:add']"></div>
                    <el-button
                        v-hasPermi="['user:add']"
                        type="primary"
                        @click="addDialog"
                    >添加</el-button>
                </div>

                <table-data
                    v-loading="loadingTable"
                    id="table"
                    ref="table"
                    :config="table_config"
                    :tableData="table_data"
                    :backgroundHeader="backgroundHeader"
                    :headerColor="headerColor"
                    @switchHandler="switchHandler"
                >
                    <template v-slot:operation="slotData">
                        <el-button
                            type="text"
                            @click="edit(slotData.data)"
                            v-hasPermi="['user:edit']"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            @click="reset(slotData.data.id)"
                            v-hasPermi="['user:resetPass']"
                        >
                            重置账密
                        </el-button>
                        <el-button
                            type="text"
                            @click="del(slotData.data)"
                            v-hasPermi="['user:delete']"
                        >
                            删除
                        </el-button>
                    </template>
                </table-data>
                <Pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getList"
                />

                <dialog-wrapper
                    :dialogObj="dialogObj"
                    @handleClose="handleClose"
                >
                    <el-tabs
                        v-model="activeName"
                        @tab-click="handleClick"
                    >
                        <el-tab-pane
                            label="基本信息"
                            name="first"
                        >
                            <el-form
                                :model="ruleForm"
                                :rules="rules"
                                ref="ruleForm"
                                label-position="right"
                                label-width="105px"
                                class="common-form"
                            >
                                <el-form-item
                                    label="登录手机号"
                                    prop="loginName"
                                >
                                    <el-input
                                        v-model="ruleForm.loginName"
                                        maxlength="20"
                                        show-word-limit
                                        placeholder="请输入登录手机号"
                                        class="input-width-medium"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label="用户名"
                                    prop="userName"
                                >
                                    <el-input
                                        v-model="ruleForm.userName"
                                        maxlength="11"
                                        show-word-limit
                                        placeholder="请输入用户名"
                                        class="input-width-medium"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label="账户密码"
                                    prop="password"
                                >
                                    <el-input
                                        v-model="ruleForm.password"
                                        @change="handlerPasswordChange"
                                        :disabled="hasRuleFormId"
                                        maxlength="10"
                                        show-word-limit
                                        placeholder="请输入账户密码"
                                        class="input-width-medium"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label="教师"
                                    prop="teacherId"
                                >
                                    <el-select
                                        v-model="ruleForm.teacherId"
                                        filterable
                                        clearable
                                        placeholder="请选择教师"
                                        class="input-width-medium"
                                    >
                                        <el-option
                                            :disabled="item.type === 0"
                                            v-for="item in teacherList"
                                            :key="item.id"
                                            :value="item.id"
                                            :label="item.teacherName"
                                        />
                                    </el-select>
                                </el-form-item>
                                <!-- <el-form-item label="所属学校" prop="schoolId">
                  <el-select
                    v-model="ruleForm.schoolId"
                    filterable
                    clearable
                    placeholder="请选择所属学校"
                    style="width: 380px"
                    :disabled="true"
                  >
                    <el-option
                      v-for="item in schoolList"
                      :key="item.id"
                      :value="item.id"
                      :label="item.label"
                    />
                  </el-select>
                </el-form-item> -->
                                <!-- <el-form-item label="所属部门" prop="teacherId">
                  <el-select
                    v-model="ruleForm.organList"
                    filterable
                    multiple
                    clearable
                    placeholder="请选择所属部门"
                    style="width: 380px"
                    :disabled="true"
                  >
                    <el-option
                      v-for="item in organList"
                      :key="item.id"
                      :value="item.id"
                      :label="item.label"
                    />
                  </el-select>
                </el-form-item> -->
                                <!-- <el-form-item label="任教科目" prop="subjectId">
                  <el-select
                    v-model="ruleForm.subjectId"
                    placeholder="请选择任教科目"
                    style="width: 380px"
                    @change="handlerSubjectChange"
                    :disabled="true"
                  >
                    <el-option
                      v-for="(item, index) in subjectList"
                      :key="index"
                      :value="item.value"
                      :label="item.label"
                    />
                  </el-select>
                </el-form-item> -->
                            </el-form>
                        </el-tab-pane>
                        <el-tab-pane
                            label="用户角色"
                            name="second"
                            style="margin-bottom: 10px;"
                        >
                            <div class="add-wrapper">
                                <div
                                    class="add-btn"
                                    @click="handlerAddCharacterGroupClick"
                                >
                                    新增
                                </div>
                                <img
                                    :src="characterGroup_add"
                                    alt="新增"
                                    class="add-img"
                                    @click="handlerAddCharacterGroupClick"
                                />
                            </div>
                            <table-data
                                id="table"
                                :config="table_config_characterGroup"
                                :tableData="table_data_characterGroup"
                                :backgroundHeader="backgroundHeader"
                                :headerColor="headerColor"
                            >
                                <template v-slot:operation="slotData">
                                    <!-- v-hasPermi="['user:delete']" -->
                                    <el-button
                                        type="danger"
                                        size="mini"
                                        @click="
                                            characterGroupDel(slotData.data)
                                        "
                                        >删除</el-button
                                    >
                                </template>
                            </table-data>
                        </el-tab-pane>
                        <el-tab-pane
                            label="数据权限"
                            name="third"
                            style="margin-bottom: 20px"
                        >
                            <el-input
                                placeholder="输入关键字进行过滤"
                                v-model="filterText"
                            >
                            </el-input>
                            <el-tree
                                ref="treeRef"
                                :data="permissionList"
                                show-checkbox
                                node-key="id"
                                :filter-node-method="filterNode"
                                :props="{
                                    children: 'child',
                                    label: 'name',
                                }"
                                @check="handleCheck"
                            />
                        </el-tab-pane>
                        <div :style="{'text-align': 'right'}">
                            <el-button
                                type="enquiry"
                                @click="handleClose"
                            >取消</el-button>
                            <el-button
                                type="primary"
                                @click="submit('ruleForm')"
                            >确定</el-button>
                        </div>
                    </el-tabs>
                </dialog-wrapper>
                <dialog-wrapper
                    :dialogObj="dialogCharacterGroupObj"
                    @handleClose="characterGroupCancellation"
                >
                    <div class="character-name-wrapper">
                        <el-input
                            v-model="characterName"
                            placeholder="角色名称"
                            style="width: 200px;margin-right: 10px"
                        ></el-input>
                        <el-button
                            type="primary"
                            @click="filterCharacterName"
                        >查询</el-button>
                    </div>
                    <table-data
                        v-if="dialogCharacterGroupObj.dialogVisible"
                        ref="multipleTable"
                        id="table"
                        :config="table_config_characterGroup_add"
                        :tableData="table_data_characterGroup_add"
                        :backgroundHeader="backgroundHeader"
                        :headerColor="headerColor"
                        :rows="confirmAddingRoleGroupsRows"
                        @rowSelect="handleRowClick"
                        @selectAll="handleSelectAllClick"
                        style="margin-bottom: 10px"
                    >
                    </table-data>
                    <div :style="{'text-align': 'right'}">
                        <el-button
                            type="enquiry"
                            @click="characterGroupCancellation"
                        >取消</el-button>
                        <el-button
                            type="primary"
                            @click="characterGroupAdd"
                        >确定</el-button>
                    </div>
                </dialog-wrapper>
            </div>
        </div>
        <error
            v-else
            :errorShow="errorShow"
        ></error>
    </div>
</template>

<script>
import {
    // 业务组件
    Pagination,
    DialogWrapper,
    // 功能组件
    Error,
    Loading,
    // 工具函数
    throttle,
    debounce,
} from "common-local";
// 逻辑运算
import TableData from "./Sub/TableData";
import systemOperations from "@/operations/system.js";
import {mapState} from "vuex";
import {SystemPersonnelModel} from "@/models/SystemPersonnel.js";
import {Encrypt} from "@/libs/secret.js";
import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter.vue";
import onResize from "@/mixins/onResize"

export default {
    mixins: [onResize],
    name: "SystemPersonnelScrollWrapper",
    components: {
        ExpandFilter,
        TableData,
        Pagination,
        DialogWrapper,
        Error,
        Loading,
    },
    data() {
        return {
            filterText: "", // 树形数据筛选
            permissionList: [], // 树形数据
            // 头部筛选
            formData: {
                btnFormType: true,
                formInline: {
                    loginName: "",
                    status: "",
                    deptId: "",
                    roleId: "",
                    userName:"",
                    teacherId:"",
                },
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "登录手机号",
                        key: "loginName",
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "状态",
                        key: "status",
                        list: [
                            {
                                label: "停用",
                                value: "0",
                            },
                            {
                                label: "启用",
                                value: "1",
                            },
                        ],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "所属部门",
                        key: "deptId",
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "用户角色",
                        key: "roleId",
                        list: [],
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "用户名",
                        key: "userName",
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "职工",
                        key: "teacherId",
                        list:[],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["user:list"],
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                    },
                ],
            },

            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "登录手机号",
                        prop: "loginName",
                        align: 'center'
                    },
                    {
                        label: "用户名",
                        prop: "userName",
                        align: 'center'
                    },
                    {
                        label: "职工",
                        prop: "teacherName",
                        labelWidth: "250px",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "用户角色",
                        prop: "roleName",
                        type: "tooltipColumn",
                        align: 'center',
                        splitSign: ",",
                        labelWidth: '220px'
                    },
                    {
                        label: "所属部门",
                        prop: "deptName",
                        type: "tooltipColumn",
                        align: 'center',
                        splitSign: ",",
                        labelWidth: '220px'
                    },
                    {
                        label: "状态",
                        prop: "status",
                        type: "switch",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "操作",
                        type: "slot",
                        align: 'center',
                        slotName: "operation",
                        className: 'text-spacing-reduction'
                    },
                ],
                check: false,
                height: "",
            },
            table_config_characterGroup: {
                thead: [
                    {
                        label: "角色名称",
                        prop: "roleName",
                    },
                    {
                        label: "操作",
                        type: "slot",
                        labelWidth: "300",
                        slotName: "operation",
                    },
                ],
                check: false,
                height: "",
            },
            table_config_characterGroup_add: {
                thead: [
                    {
                        label: "角色名称",
                        prop: "roleName",
                    },
                ],
                check: true,
                height: "",
                rowkey: "id",
                checkReserveSelection: true,
            },
            // 表格数据
            table_data: [],
            table_data_characterGroup: [], // 角色组展示
            table_data_characterGroup_add: [], // 角色组数据
            table_data_characterGroup_add_ori: [], // 角色组原始数据
            confirmAddingRoleGroups: [], //  确认添加角色组
            confirmAddingRoleGroupsRows: [], // 默认选中的下标
            // 分页器
            total: 0,
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                loginName: "",
                roleId: "",
                userName: "",
            },
            // 弹出框配置
            dialogObj: {
                title: "添加用户",
                dialogVisible: false,
                width: "660px",
            },
            dialogCharacterGroupObj: {
                title: "添加角色",
                dialogVisible: false,
                width: "640px",
            },
            activeName: "first",

            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: false,

            // 表头
            backgroundHeader: "#FAFBFC",
            // 表头字体
            headerColor: "",

            // 表单
            ruleForm: {
                id: "",
                userName: "", // 用户名
                loginName: "", // 登录名
                password: "", // 账户密码
                teacherId: "", // 教师Id
                schoolId: "", // 所属学校
                organList: [], // 所属部门
                subjectId: "", // 任教科目id
                subjectName: "", // 任教科目名称
                roleList: [], // 用户角色
                powerOrganList: [], // 数据权限
            },
            password: "",
            rules: {
                loginName: [
                    {
                        message: "手机号格式错误",
                        trigger: "blur",
                        pattern:/^1[3|4|5|6|7|8|9][0-9]{9}$/
                    },
                    {
                        required: true,
                        message: "请输入登录手机号",
                        trigger: "blur",
                    },
                ],
                userName: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur",
                    },
                ],
            },

            schoolId: "",
            roleList: [],
            deptList: [],
            organList: [], // 所属部门下拉
            subjectList: [], // 任教科目
            schoolList: [], // 所属学校
            teacherList: [],
            curTchId: "",
            isNull: false,
            department: {
                name: "",
                schoolId: "",
                organType: "1",
            },
            // 用户组添加
            characterName: "",
            firstTime: true,
        };
    },
    computed: {
        ...mapState({
            templateInfo: (state) => state.templateInfo,
        }),
        characterGroup_add() {
            return require("@/assets/images/characterGroup_add.png");
        },
        hasRuleFormId() {
            return this.ruleForm.id ? true : false;
        },
    },
    created() {
        this.firstTime = false;
        this.schoolId = this.$store.state.schoolId;
        this.init();
    },
    activated() {
        if (this.firstTime) {
            this.init();
        }
    },
    deactivated() {
        this.firstTime = true;
    },
    mounted() {
    },
    methods: {
        // clearTeacher () {
        //   this.ruleForm.teacherId = ''
        //   // console.log(this.ruleForm, 'ruleForm');
        // },
        async init() {
            // await this.getDeptList();

            await this.initAssignment();
            await this.getDepartmentList();
            await this.getSubjectList();
            await this.getAllDepartmentList();
            await this.getConditionList();
            await this.getList();
            await this.getRoleList();
            await this.getTeacherList();
        },
        /**
         * @Description: 初始化赋值
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2022-12-30 15:30:30
         */
        initAssignment() {
            this.department.schoolId = this.schoolId;
            // 新增赋值
            this.schoolList.push({
                id: this.schoolId,
                label: this.$store.state.schoolName,
            });
            this.ruleForm.schoolId = this.schoolId;
        },
        /**
         * @Description: 获取所属部门
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2022-12-30 15:33:58
         */
        async getDepartmentList() {
            const systemPersonnelModel = new SystemPersonnelModel();
            await systemPersonnelModel
                .getDepartmentList(this.department)
                .then((res) => {
                    if (res.data.code == "200") {
                        setTimeout(() => {
                            res.data.data.list.forEach((item) => {
                                this.$set(item, "label", item.name);
                                this.$set(item, "value", item.id);
                            });

                            this.formData.data.forEach((item) => {
                                if (item.key == "deptId") {
                                    this.$set(item, "list", res.data.data.list);
                                }
                            });

                            this.organList = res.data.data.list;
                            console.log(this.formData, "this.formData");
                        }, 100);
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        /**
         * @Description: 获取任教科目
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2022-12-30 16:55:39
         */
        async getSubjectList() {
            const systemPersonnelModel = new SystemPersonnelModel();
            await systemPersonnelModel
                .getSubjectList({schoolId: this.schoolId})
                .then((res) => {
                    if (res.data.code == "200") {
                        console.log(res.data.data, "任教科目");
                        setTimeout(() => {
                            res.data.data.forEach((item) => {
                                this.$set(item, "value", item.id);
                                this.$set(item, "label", item.name);
                            });
                            this.subjectList = res.data.data;
                        }, 100);
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        /**
         * @Description: 获取部门所有的树形下拉
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-01-03 09:24:01
         */
        async getAllDepartmentList() {
            const systemPersonnelModel = new SystemPersonnelModel();
            await systemPersonnelModel
                .getAllDepartmentList({schoolId: this.schoolId})
                .then((res) => {
                    if (res.data.code == "200") {
                        setTimeout(() => {
                            this.permissionList = res.data.data;
                        console.log(this.permissionList, "树形部门");

                        });
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        /**
         * @Description: 获取角色下拉
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-01-03 10:41:18
         */
        async getConditionList() {
            const systemPersonnelModel = new SystemPersonnelModel();
            await systemPersonnelModel
                .getConditionList({schoolId: this.schoolId})
                .then((res) => {
                    if (res.data.code == "200") {
                        setTimeout(() => {
                            this.table_data_characterGroup_add_ori =
                                res.data.data;
                            this.table_data_characterGroup_add = res.data.data;
                        });
                        console.log(
                            this.table_data_characterGroup_add_ori,
                            "获取角色组",
                        );
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        getTeacherList() {
            this._fet("/school/schoolTeacher/queryTeacherList", {
                schoolId: this.schoolId,
            }).then((res) => {
                this.handleRes(res, () => {
                    setTimeout(() => {
                        res.data.data.forEach((item) => {
                            this.$set(item, "label", item.teacherName);
                            this.$set(item, "teacherName", item.status == '1' ? item.teacherName : item.teacherName + '（离职）');
                            this.$set(item, "value", item.id);
                        });
                        this.teacherList = res.data.data;
                        this.formData.data.forEach((item) => {
                                if (item.key == "teacherId") {
                                    this.$set(item, "list", res.data.data);
                                }
                            });
                        console.log(this.teacherList, "教师下拉");
                    }, 200);
                });
            });
        },
        getRoleList() {
            this._fet("/school/schoolRole/getRoleList", {
                schoolId: this.schoolId,
            }).then((res) => {
                this.handleRes(res, () => {
                    setTimeout(() => {
                        this.roleList = res.data.data.map((item) => ({
                            label: item.roleName,
                            value: item.id,
                        }));
                        this.formData.data.forEach((item) => {
                            if (item.key === "roleId") {
                                item.list = this.roleList;
                            }
                        });
                    }, 200);
                });
            });
        },
        getDeptList() {
            this._fet("/school/schoolOrgan/listByCondition", {
                schoolId: this.schoolId,
            }).then((res) => {
                this.handleRes(res, () => {
                    setTimeout(() => {
                        this.deptList = this.setDateForTree(res.data.data.list);
                        console.log(this.deptList, "this.deptList");
                    }, 200);
                });
            });
        },
        /**
         * @Descripttion: 编辑
         * @DoWhat: 编辑
         * @UseScenarios: 编辑
         * @Attention:
         * @Author: lyx
         * @Date: 2022-11-28 13:55:47
         * @param {*} function
         */

        edit: throttle(async function (data) {
            this.dialogObj.title = "编辑用户";

            const systemPersonnelModel = new SystemPersonnelModel();
            await systemPersonnelModel
                .getSchoolUserInfo({id: data.id})
                .then((res) => {
                    if (res.data.code == "200") {
                        Object.keys(res.data.data).forEach((key) => {
                            Object.keys(this.ruleForm).forEach((subKey) => {
                                if (key == subKey && subKey !== "organList") {
                                    this.ruleForm[subKey] = res.data.data[key];
                                }
                            });
                        });

                        this.ruleForm.password = "**********";
                        this.ruleForm.organList = [];

                        if (
                            res.data.data.organList &&
                            res.data.data.organList.length > 0
                        ) {
                            res.data.data.organList.forEach((item) => {
                                this.ruleForm.organList.push(item.organId);
                            });
                        }

                        this.table_data_characterGroup = res.data.data.roleList;

                        let powerSelectList = [];
                        if (
                            res.data.data.powerList &&
                            res.data.data.powerList.length > 0
                        ) {
                            res.data.data.powerList.forEach((item) => {
                                let obj = {
                                    organId: item.organId,
                                    organName: item.organName,
                                };
                                powerSelectList.push(item.organId);
                                this.ruleForm.powerOrganList.push(obj);
                            });
                        }

                        this.dialogObj.dialogVisible = true;

                        if (powerSelectList && powerSelectList.length > 0) {
                            this.$nextTick(() => {
                                powerSelectList = this.handleTreeNodeCheckStatus(powerSelectList);
                                this.$refs.treeRef.setCheckedKeys(
                                    powerSelectList
                                );
                            });
                        }
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        }, 200),
        handleTreeNodeCheckStatus(idsArr) {
            console.log('idsArr=====', idsArr);
            let ids = [];
            getNode(this.permissionList);
            console.log('ids=======', ids);
            return this._.differenceWith(idsArr, ids, this._.isEqual)
            function getNode(arr) {
                arr.forEach(item => {
                    if(idsArr.includes(item.id)) {
                        if(item.child.length > 0) {//有子级 剔除这个item.id
                            ids.push(item.id);
                            getNode(item.child)
                        }
                    } else {
                        if(item.child.length > 0) {
                            getNode(item.child)
                        }
                    }
                })
            }

        },
        reset: throttle(function (id) {
            this.$confirm("此操作将重置密码, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this._fet("/school/schoolUser/resetPassword", {
                        id,
                    }).then((res) => {
                        this.handleRes(res, () => {
                            this.$message.success(
                                "重置密码成功，已重置为" + res.data.data,
                            );
                            this.getList();
                        });
                    });
                })
                .catch(() => {});
        }, 200),
        del(data) {
            this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this._fet("/school/schoolUser/deleteUser", {
                        id: data.id,
                        userName: data.userName,
                        loginName: data.loginName,
                    }).then((res) => {
                        this.handleRes(res, () => {
                            this.$message.success("删除成功");
                            this.getList();
                            this.getTeacherList();
                        });
                    });
                })
                .catch(() => {});
        },
        clickBtn(ev) {
            switch (ev.item.fn) {
                case "primary":
                    this.listQuery = systemOperations.filterHeaderFrom(
                        ev.data,
                        this.listQuery,
                    );
                    this.getList(1);
                    break;
                case "add":
                    this.addDialog();
                    break;
                case "reset":
                    systemOperations.resetFilterData(
                        this.formData.data,
                        this.listQuery,
                    );
                    this.getList(1);
                    break;
                default:
                    break;
            }
        },
        treeClick(data) {
            this.ruleForm.deptId = data.id;
            this.ruleForm.deptName = data.name;
        },
        addDialog() {
            this.dialogObj.dialogVisible = true;
            this.dialogObj.title = "新增用户";
        },
        getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1;
            }
            this.loadingTable = true;
            this._fet("/school/schoolUser/list", this.listQuery).then((res) => {
                this.handleRes(res, () => {
                    setTimeout(() => {
                        this.total = res.data.data.totalCount;
                        this.table_data = res.data.data.list;
                        console.log(this.table_data, "this.table_data");
                        this.loadingTable = false;
                    }, 200);
                });
            });
        },
        /**
         * @Description: 切换状态
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-01-04 11:44:48
         */
        switchHandler(data) {
            const systemPersonnelModel = new SystemPersonnelModel();
            systemPersonnelModel
                .setSwitchingUserStatus({id: data.id, status: data.status})
                .then((res) => {
                    if (res.data.code == "200") {
                        this.$message.success("修改成功");
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });

            console.log(data, "data");
        },
        handleClose() {
            this.$refs.ruleForm.resetFields();
            this.$refs.treeRef.setCheckedKeys([]);
            this.ruleForm.id = "";
            this.ruleForm.userName = "";
            this.ruleForm.loginName = "";
            this.ruleForm.password = "";
            this.ruleForm.teacherId = "";
            this.ruleForm.organList = [];
            this.ruleForm.subjectId = "";
            this.ruleForm.subjectName = "";
            this.ruleForm.roleList = [];
            this.ruleForm.powerOrganList = [];
            this.table_data_characterGroup = [];
            this.dialogObj.dialogVisible = false;
        },
        submit() {
            this.$refs.ruleForm.validate((val) => {
                if (val) {
                    this.ruleForm.schoolId = this.schoolId;
                    this.ruleForm.productId = "cloud_campus";

                    if (!this.ruleForm.id) {
                        this.ruleForm.password = Encrypt(
                            this.ruleForm.password,
                        );
                    }

                    console.log(
                        this.ruleForm.organList,
                        "this.ruleForm.organList",
                    );
                    let organList = JSON.parse(
                        JSON.stringify(this.ruleForm.organList),
                    );
                    this.ruleForm.organList = [];

                    this.organList.forEach((item) => {
                        organList.forEach((subItem) => {
                            if (item.id == subItem) {
                                let obj = {
                                    id: item.id,
                                    name: item.label,
                                };
                                this.ruleForm.organList.push(obj);
                            }
                        });
                    });

                    this.ruleForm.roleList = [];

                    if (
                        this.table_data_characterGroup &&
                        this.table_data_characterGroup.length > 0
                    ) {
                        this.table_data_characterGroup.forEach((item) => {
                            let obj = {
                                roleId: item.id,
                                roleName: item.roleName,
                            };
                            this.ruleForm.roleList.push(obj);
                        });
                    }

                    console.log(this.ruleForm, "this.ruleForm");

                    if (!this.ruleForm.id) {
                        this._fet(
                            "/school/schoolUser/insert",
                            this.ruleForm,
                        ).then((res) => {
                            if (res.data.code == "200") {
                                this.$message.success("添加用户成功");
                                this.getList();
                                this.getTeacherList();
                                this.handleClose();
                                this.activeName = "first";
                            } else if (res.data && res.data.msg) {
                                this.ruleForm.password = this.password;
                                this.$message.error(res.data.msg);
                            }
                        });
                    } else {
                        this._fet(
                            "/school/schoolUser/edit",
                            this.ruleForm,
                        ).then((res) => {
                            this.handleRes(res, () => {
                                this.$message.success("编辑用户成功");
                                this.getList();
                                this.getTeacherList();
                                this.handleClose();
                                this.activeName = "first";
                            });
                        });
                    }
                } else {
                    this.$message.error("请填写完整表单～");
                }
            });
        },
        setDateForTree(data) {
            let parent = data.filter((p) => {
                return p.parentOrg == 0;
            });
            let children = data.filter((p) => {
                return p.parentOrg != 0;
            });
            return this.setTreeChildren(parent, children);
        },
        setTreeChildren(parent, children) {
            if (parent.length === 0) {
                parent = children;
            } else {
                parent.map((p) => {
                    children.map((c, i) => {
                        if (p.id === c.parentOrg) {
                            let _c = JSON.parse(JSON.stringify(children));
                            _c.splice(i, 1);
                            this.setTreeChildren([c], _c);
                            if (p.children) {
                                p.children.push(c);
                            } else {
                                p.children = [c];
                            }
                        }
                    });
                });
            }
            return parent;
        },
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        initTabColor() {
            this.template_info = this.templateInfo;
            this.template_info.forEach((p, i) => {
                if (p.sel) {
                    this.headerColor = p.textColor;
                }
            });
        },
        /**
         * @Description: 切换新增选项
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2022-12-29 09:50:11
         */
        handleClick(tab, event) {
            // console.log(tab, event,'e')
        },
        /**
         * @Description: 删除
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2022-12-29 14:55:49
         * @param {*} data
         */
        characterGroupDel(data) {
            this.$confirm("确定要删除吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    console.log(data, "data");
                    this.table_data_characterGroup.forEach((item, index) => {
                        if (data.id == item.id) {
                            console.log(index, "下标");
                            this.table_data_characterGroup.splice(index, 1);
                        }
                    });

                    this.confirmAddingRoleGroups.forEach((item, index) => {
                        if (data.id == item.id) {
                            this.confirmAddingRoleGroups.splice(index, 1);
                        }
                    });
                    console.log(
                        this.table_data_characterGroup,
                        "删除后的角色组",
                    );
                })
                .catch(() => {});
        },
        /**
         * @Description: 新增角色组
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2022-12-29 14:55:54
         */
        handlerAddCharacterGroupClick() {
            this.dialogCharacterGroupObj.dialogVisible = true;
            this.confirmAddingRoleGroupsRows = [];

            if (this.table_data_characterGroup.length > 0) {
                this.table_data_characterGroup.map((item) => {
                    this.confirmAddingRoleGroupsRows.push(
                        this.table_data_characterGroup_add.findIndex(
                            (subItem) => {
                                return subItem.id == item.id;
                            },
                        ),
                    );
                });
            }

            this.$nextTick(() => {
                this.$refs.multipleTable.toggleRowSelection();
            });
            console.log(this.confirmAddingRoleGroupsRows, "table已经选中的");
        },
        /**
         * @Description: 用户组弹窗，提交
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2022-12-29 15:02:23
         */
        characterGroupSubmit() {
            this._fet("/school/schoolUser/insert", this.ruleForm).then(
                (res) => {
                    this.handleRes(res, () => {
                        this.$message.success("添加用户成功");
                        // this.getList();
                        // this.getTeacherList();
                        // this.handleClose();
                    });
                },
            );
        },
        
        /**
         * @Description: tree 属性筛选
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2022-12-29 15:26:55
         * @param {*} value
         * @param {*} data
         */
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        /**
         * @Description: 数据权限
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2022-12-29 15:28:46
         */
        handleCheck() {
            //console.log(this.$refs.treeRef.getCheckedKeys(),'获取选中')
            let selInfo = [],
                selData = this.$refs.treeRef.getCheckedKeys();

            this.getPermissionContent(this.permissionList, selData, selInfo);
            console.log(selInfo, "selInfo");
            this.ruleForm.powerOrganList = selInfo;
        },
        getPermissionContent(list, selData, selInfo) {
            list.forEach((item) => {
                selData.forEach((subItem) => {
                    if (item.id == subItem) {
                        console.log(item, "item");
                        let obj = {
                            organId: item.id,
                            organName: item.name,
                        };
                        selInfo.push(obj);
                    }
                });

                if (item.child && item.child.length > 0) {
                    this.getPermissionContent(item.child, selData, selInfo);
                }
            });
        },
        /**
         * @Description: 添加选择用户组
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-01-03 14:33:03
         */
        handleRowClick(selection, row) {
            this.confirmAddingRoleGroups = this.formatArr(selection);
        },
        /**
         * @Description: 添加选择用户组的全选
         * @Author: 闫乔
         * @Date: 2024-09-10 10:21:18
         * @param {*} selection
         */        
        handleSelectAllClick(selection) {
            this.confirmAddingRoleGroups = this.formatArr(selection);
        },
        /**
         * @Description: 筛选用户组
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-01-03 14:39:33
         */
        filterCharacterName() {
            this.table_data_characterGroup_add =
                this.table_data_characterGroup_add_ori.filter((item) => {
                    return item.roleName.indexOf(this.characterName) != -1;
                });
            console.log(this.table_data_characterGroup_add, "筛选后的名称");
        },
        /**
         * @Description: 确认添加角色组
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-01-03 15:08:13
         */
        characterGroupAdd() {
            this.table_data_characterGroup = JSON.parse(
                JSON.stringify(this.confirmAddingRoleGroups),
            );

            this.characterGroupCancellation();
            console.log(this.confirmAddingRoleGroups, "确认添加角色1");
        },
        /**
         * @Description: 取消添加角色组
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-01-03 15:08:13
         */
        characterGroupCancellation() {
            this.confirmAddingRoleGroups = [];
            this.dialogCharacterGroupObj.dialogVisible = false;
            this.characterName = '';
            this.table_data_characterGroup_add = JSON.parse(JSON.stringify(this.table_data_characterGroup_add_ori));
            console.log(this.confirmAddingRoleGroups, "取消添加角色1");
        },
        /**
         * @Description: 获取任教科目名称
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-01-03 19:24:14
         */
        handlerSubjectChange() {
            this.subjectList.forEach((item) => {
                if (this.ruleForm.subjectId == item.id) {
                    this.ruleForm.subjectName = item.name;
                }
            });
        },
        /**
         * @Description: 去重
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-01-03 15:16:34
         */
        formatArr(arr) {
            let map = new Map();
            for (let item of arr) {
                if (!map.has(item.id)) {
                    map.set(item.id, item);
                }
            }
            return [...map.values()];
        },
        /**
         * @Description: 获取最新密码
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-01-10 09:49:53
         */
        handlerPasswordChange() {
            this.password = this.ruleForm.password;
        },
    },
    watch: {
        templateInfo: {
            handler() {
                this.initTabColor();
            },
            deep: true,
            immediate: true,
        },
        filterText(val) {
            this.$refs.treeRef.filter(val);
        },
    },
};
</script>
<style lang="scss" scoped>
.filter-wrap {
    display: flex;
    background-color: #fff;
    padding: 10px 20px 0;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;
    }
}

.el-tabs {
    width: 564px;
    margin-top: -10px;
}
.add-wrapper {
    display: flex;
    margin-bottom: 14px;

    .add-btn {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #363b40;
        cursor: pointer;
    }

    .add-img {
        width: 20px;
        height: 20px;
        padding-left: 8px;
        cursor: pointer;
    }
}

.character-name-wrapper {
    margin-bottom: 20px;
}
.el-dropdown-link {
    cursor: pointer;
}
</style>
